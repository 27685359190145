import React, { useState } from "react";
import axios from "axios";
import Layout from "../components/layout";
import styles from "../components/blog-post.module.css";

const NewsletterSignup = () => {
  const [success, setSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    const email = form.get("email");
    axios
      .post("https://api.passiv.com/api/v1/emailsubscribe", { email })
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Layout>
      <div className={styles.formContainerCentered}>
        <h2 className={styles.newsletterTitle}>Get all the insider financial info</h2>

        <p className={styles.newsletterDescription}>Sign up to our email newsletter</p>

        <form onSubmit={handleSubmit} className={styles.newsletterForm}>
          <input
            type="email"
            name="email"
            required
            placeholder="Email"
            className={styles.newsletterInput}
          />

          <button type="submit" disabled={success} className={styles.newsletterButton}>
            {success ? "Success" : "Sign Up"}
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default NewsletterSignup;
